import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Thing} from '../../_internal/thing';

@Component({
    selector: 'app-chronograf-button',
    templateUrl: './chronograf-button.component.html',
    styleUrls: ['./chronograf-button.component.scss']
})
export class ChronografButtonComponent implements OnInit {

    @Input() thing: Thing;
    @Input() mode = 'default';

    constructor() {
    }

    ngOnInit(): void {
    }

    public openChronograf() {
        const domain = environment.production ? 'ai' : 'dev';
        const chronografBase = 'https://chronograf.watergate';
        const source = environment.production ? '2' : '1';
        const path = `sources/${source}/chronograf/data-explorer?query=`;

        const fromQuery = 'FROM "telegraf-telemetry"."autogen"."mqtt_consumer"';
        const conditionQuery = `WHERE time > :dashboardTime: AND time < :upperDashboardTime: AND "device_id"='${this.thing.id}' LIMIT 2000`;
        let query = '';
        switch (this.mode) {
            case 'battery':
                // eslint-disable-next-line max-len
                query = `SELECT time, first_battery_voltage, second_battery_voltage ${fromQuery} ${conditionQuery}`;
                break;
            case 'default':
            default:
                // eslint-disable-next-line max-len
                query = `SELECT time, pressure, water_flow, water_temp, status_0 ${fromQuery} ${conditionQuery}`;
                break;
        }

        const url = `${chronografBase}.${domain}/${path}${(query)}`;
        this.openNewTab(url);
    }

    private openNewTab(url: string) {
        window.open(url);
    }
}
