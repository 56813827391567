import {Component, OnDestroy, OnInit} from '@angular/core';
import {JobService} from '../../../providers/services/job.service';
import {ActivatedRoute, Router} from '@angular/router';
import {JobDescription, JobExecution} from '../../../_internal/job';
import {JobExecutionParameters} from '../../../providers/resources/dto/job.response';
import {MatDialog} from '@angular/material/dialog';
import {JobExecutionComponent} from '../job-execution/job-execution.component';
import * as _ from 'lodash';
import {interval, Subscription} from 'rxjs';
import {ConfirmationDialogComponent} from '../../../../common/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FirmwareService} from '../../../providers/services/firmware-service';
import {ToastrService} from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';
import { UserService } from '../../../providers/services/user.service';

@Component({
    selector: 'app-job-page',
    templateUrl: './job-page.component.html',
    styleUrls: ['./job-page.component.scss']
})
export class JobPageComponent implements OnInit, OnDestroy {
    public statusCheck: Subscription;
    public jobId: string;
    public jobDescription: JobDescription;
    public jobExecutions: JobExecution[];
    public jobExecutionParams: JobExecutionParameters;
    public deviceType: string;
    private readonly sonicTags = ['END_DEVICE', 'smart_valve', 'SONIC_WIFI'];

    constructor(private jobService: JobService,
                private modalService: BsModalService,
                private router: Router,
                private toastrService: ToastrService,
                private firmwareService: FirmwareService,
                private activatedRoute: ActivatedRoute,
                private dialog: MatDialog,
                private userService: UserService) {
    }

    ngOnDestroy(): void {
        if (this.statusCheck) {
            this.statusCheck.unsubscribe();
        }
    }

    public navigateToDevice(deviceId: string): void {
        this.router.navigate([this.isSonic() ? 'things' : 'hubs', deviceId]);
    }

    public isSonic(): boolean {
        return _.includes(this.sonicTags, this.deviceType);
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            this.jobId = params.id;
            this.jobExecutionParams = {target: 'job', target_id: this.jobId};
            this.fetchJob();
        });
        this.refreshDataIfNotComplete();
    }

    public onJobExecution(jobExecution: JobExecution): void {
        const deviceId = this.deviceType !== 'SONIC_WIFI' ? jobExecution.target_id : jobExecution.target_id.slice(0,-1) + 0;
        const dialogRef = this.dialog.open(JobExecutionComponent, {
            width: '600px',
            data: {
                jobId: this.jobId,
                target: jobExecution.target,
                target_id: deviceId,
            }
        });
    }

    public canCancelJob(jobDescription, jobExecution) {
        return (jobDescription?.document?.job_type === 'UPDATE' && jobExecution?.status === 'IN_PROGRESS'&& jobDescription?.status === 'IN_PROGRESS') &&
            (this.userService.isFirmwareMaster() || this.userService.isFirmwareManager() || this.userService.isRoot());
    }

    public cancelJob(jobId: string, jobExecution: JobExecution) {
        const deviceId = this.deviceType !== 'SONIC_WIFI' ? jobExecution.target_id : jobExecution.target_id.slice(0,-1) + 0;

        this.modalService.show(ConfirmationDialogComponent, {
            initialState: {
                message: 'Are you sure you want to cancel job <strong>' + jobId + '</strong> for device <strong>' + deviceId + '</strong>?',
                title: 'Cancel job',
                button: 'Yes',
                cancelButton: 'No'
            }
        });

        const subscription = this.modalService.onHide
            .subscribe((reason) => {
                if (reason === 'action-confirmed') {
                    this.cancelJobOrExecution(jobId, deviceId)
                        .subscribe(response => {
                            this.fetchJob();
                            this.toastrService.info('Job cancellation request sent.', 'Settings.', environment.toastSettings);
                        }, (error) => {
                            this.toastrService.error('An error has ocured. Error code: ' + error.status + 'Error message' + error.message, 'Loading page data.', environment.toastSettings);
                        });
                }
                subscription.unsubscribe();
            });

    }

    private cancelJobOrExecution(jobId: string, deviceId: string) {
        // check if the job affects one or more devices
        if (this.jobExecutions.length > 1) {
            // cancel only the execution for a single device, if there are more
            return this.firmwareService.cancelFirmwareUpgrade(jobId, deviceId);
        } else {
            // cancel the whole job if it's for a single device only
            return this.firmwareService.cancelFirmwareUpgrade(jobId);
        }
    }

    private fetchJob(): void {
        this.jobService.getJobDescribe(this.jobId).subscribe((data) => {
            this.jobDescription = data;
            this.fetchJobExecutions();
        });
    }

    private refreshDataIfNotComplete() {
        this.statusCheck = interval(10000)
            .subscribe(() => {
                if (this.jobExecutions[0].status !== 'SUCCEEDED' && this.jobExecutions[0].status !== 'FAILED') {
                    this.fetchJobExecutions();
                    return;
                }
                this.statusCheck.unsubscribe();
            });
    }

    private fetchJobExecutions(): void {
        this.jobService.getJobsExecutions(this.jobExecutionParams).subscribe((data) => {
            this.jobExecutions = data.job_executions;
            this.deviceType = _.get(this.jobDescription, 'document.data.device_type', '');
            if(this.deviceType === 'SONIC_WIFI'){
                // eslint-disable-next-line guard-for-in
                for(const jobIndex in this.jobExecutions){
                    this.jobExecutions[jobIndex].target_id = this.jobExecutions[jobIndex].target_id.slice(0,-1) + 1;
                }
            }
        });
    }
}
