import {ConfirmationDialogComponent} from '../../../../common/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ThingResource} from '../../../providers/resources/thing.resource';
import {Thing} from '../../../_internal/thing';
import {PusherService} from '../../../providers/services/pusher.service';
import {BlockUIService} from 'ng-block-ui';
import {finalize, switchMap, take} from 'rxjs/operators';
import {AvailableFirmwareListComponent} from '../../../components/firmware-trigger/list/available-firmware-list.component';
import {PusherStateChangeMessage} from '../../../_internal/pusher.state.change.message';
import {environment} from '../../../../../environments/environment';
import {Observable, Subscription} from 'rxjs';
import {ThingService} from '../../../providers/services/thing.service';
import {Appliance} from '../../../_internal/appliance';
import * as _ from 'lodash';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {ToastrService} from 'ngx-toastr';
import {ThingEditPageComponent} from '../../things/thing-edit/thing-edit-page.component';
import {ValueStatusButtonComponent} from '../../things/value-status-button/value-status-button.component';
import {Hub} from '../../../_internal/hub';
import {HubResource} from '../../../providers/resources/hub.resource';
import {HubService} from '../../../providers/services/hub.service';
import {UserService} from '../../../providers/services/user.service';
import {ValveStatesService} from '../../../providers/services/valve-states.service';
import {FirmwareSettings} from '../../../providers/resources/dto/firmware-device-settings.response';

@Component({
    selector: 'app-sonic-wifi-page',
    templateUrl: './sonic-wifi-page.component.html',
    styleUrls: ['./sonic-wifi-page.component.scss']
})

export class SonicWifiPageComponent implements OnInit, OnDestroy {
    public thingId: string;
    public thing: Thing;
    public modalRef: BsModalRef;
    public loadingState = false;
    public pusherChannel;
    public applianceStream: Observable<Appliance[]>;
    public activeTab = 'events';
    public statusCheck: Subscription;
    public telemetryStatus = true;
    public hubId: string;
    public hub: Hub;
    public firmwareSettings: FirmwareSettings;

    constructor(private thingResource: ThingResource,
                private thingService: ThingService,
                private modalService: BsModalService,
                private blockUI: BlockUIService,
                private pusher: PusherService,
                private activatedRoute: ActivatedRoute,
                private toastrService: ToastrService,
                private hubService: HubService,
                private hubResource: HubResource,
                private router: Router,
                private valveStatesService: ValveStatesService,
                public userService: UserService,
                private cdRef: ChangeDetectorRef,) {
    }

    ngOnInit() {
        this.thingId = this.activatedRoute.snapshot.paramMap.get('id');
        this.fetchDevice();
    }

    ngOnDestroy() {
        if (this.statusCheck) {
            this.statusCheck.unsubscribe();
        }
        ;
        this.closePusher();
    }

    public closePusher(): void {
        this.pusher.unsubscribe(this.thing.property_id);
    }

    public fetchDevice(pusher = true): void {
        this.blockUI.start('loader', 'Loading...');
        this.thingResource.getThing(this.thingId)
            .pipe(
                switchMap(response => {
                    this.thing = response.thing;
                    this.thingId = response.thing.id;
                    this.hubId = response.thing.hub_id;
                    return this.hubResource.get(this.hubId);
                })
            )
            .subscribe({
                next: response => {
                    this.hub = response.hub;
                    if (pusher) {
                        this.initPusher();
                    }
                    this.cdRef.detectChanges();
                },
                error: err => {
                    this.toastrService.error('An error has ocured while fetching the data. Error code: ' + err.error, 'Error.', environment.toastSettings);
                },
            });
    }

    public fetchAfterSettingsChange(pusher = true): void {
        this.blockUI.start('loader', 'Loading...');
        this.thingResource.getThing(this.thingId)
            .pipe(finalize(() => this.blockUI.stop('loader')))
            .subscribe(response => {
                this.thing = response.thing;
                if (pusher) {
                    this.initPusher();
                }
            });
    }

    public fetchHub(): void {
        this.hubResource.get(this.hubId).subscribe(res => {
            this.hub = res.hub;
        });
    }

    public toggleTelemetryCard(status: boolean): void {
        this.telemetryStatus = status;
    }

    public openEditModal(): void {
        this.modalRef = this.modalService.show(ThingEditPageComponent, {
            initialState: {
                thing: _.cloneDeep(this.thing)
            }
        });
        this.modalService.onHide
            .pipe(take(1))
            .subscribe(() => this.fetchAfterSettingsChange(false));
    }

    public openValveStatusModal(): void {
        this.modalRef = this.modalService.show(ValueStatusButtonComponent, {
            initialState: {
                thing: _.cloneDeep(this.thing),
                state: _.clone(this.thing.state)
            }
        });
        this.modalRef.content.onStateChange.subscribe(result => {
            this.loadingState = true;
        });
        this.modalService.onHide
            .pipe(take(1))
            .subscribe(() => this.fetchAfterSettingsChange(false));
    }

    public initPusher(): void {
        this.pusherChannel = this.pusher.init(this.thing.property_id);
        this.pusherChannel.bind(environment.stateChangePusherEvent, (message: PusherStateChangeMessage) => {
            if (message.message.device_id === this.thingId) {
                this.thing.state = message.message.state;
                this.loadingState = false;
            }
        });
    }

    public openUpdateDialog() {
        this.modalService.show(AvailableFirmwareListComponent, {
            initialState: {listType: 'Firmwares', hub: this.hub},
            class: 'firmware-update-list modal-xl',
        });
        const subscription = this.modalService.onHide.subscribe((event) => {
            if (event === 'success') {
                this.fetchHub();
                subscription.unsubscribe();
            }
        });
    }

    public triggerPressureTest() {
        this.modalService.show(ConfirmationDialogComponent, {
            initialState: {
                message: `Are you sure you want to trigger pressure test?`,
                title: 'Trigger pressure test',
                button: 'Trigger'
            }
        });
        const subscription = this.modalService.onHide.subscribe((reason) => {
            if (reason === 'action-confirmed') {
                this.thingService.triggerPressureTests(this.thing.id).subscribe(
                    res => {
                        this.toastrService.success('Pressure test was triggered.', 'Settings.', environment.toastSettings);
                    },
                    error => {
                        this.toastrService.error('An error has ocured while triggering pressure test. Error code: ' + error.status + 'Message' + error.message, 'Settings.', environment.toastSettings);
                    }
                );
            }
            subscription.unsubscribe();
        });
    }

    public tabSelect($event: TabDirective): void {
        if ($event.id) {
            this.activeTab = $event.id;
        }
    }


    public deleteHub(): void {
        if (window.confirm(`Are you sure you want to delete ${this.hubId}?`)) {
            this.hubService.deleteHub(this.hubId).subscribe(
                res => {
                    this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                    this.router.navigateByUrl('/things');
                },
                error => {
                    this.toastrService.error('An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
                }
            );
        }
    }

    public returnValveState(thing: Thing): string {
        return this.valveStatesService.returnValveState(thing);
    }

    private openNewTab(url: string) {
        window.open(url);
    }
}
