<div class="modal-content">
 	<div class="modal-header">
 		<h4 class="modal-title"> {{modalTitle}} </h4>
 	</div>
     <div class="modal-body">
		<div class="modal-content left-align">
			<div>Description: {{modalContent.description}}</div>
			<div>Operator id: {{modalContent.operator_id}}</div>
			<div>Timestamp: {{modalContent.timestamp}}</div>
		</div>
     </div>
 	<div class="modal-footer">
 		<button type="button"
 				class="btn btn-secondary"
 				(click)="onCancel()">Close
 		</button>
 	</div>
 </div>