<div class="row">
    <div class="col-lg-6">

        <div class="card mb-4"
             *ngIf="jobDescription">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> Job Description
                <span class="float-end">
        </span>

            </div>
            <div class="card-body">
                <dl class="dl-horizontal">

                    <dt>Id:</dt>
                    <dd>
                        <span class="text text-small">{{jobDescription.jobId}}</span>
                    </dd>

                    <dt>Status:</dt>
                    <dd>
                        {{jobDescription.status}}
                    </dd>

                    <dt>Target Selection:</dt>
                    <dd>
                        {{jobDescription.targetSelection}}
                    </dd>

                    <dt>Created at:</dt>
                    <dd>
                        {{jobDescription.createdAt * 1000 | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}
                    </dd>

                    <dt>Completed at:</dt>
                    <dd>
                        {{jobDescription.completedAt * 1000 | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}
                    </dd>

                    <dt>Last updated at:</dt>
                    <dd>
                        {{jobDescription.lastUpdatedAt * 1000 | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}
                    </dd>

                </dl>
            </div>
        </div>

        <div class="card mb-4"
             *ngIf="jobDescription">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> Job Process Details
                <span class="float-end">
        </span>

            </div>
            <div class="card-body">
                <dl class="dl-horizontal">

                    <dt>Number of CanceledThings:</dt>
                    <dd>
                        {{jobDescription.jobProcessDetails.numberOfCanceledThings}}
                    </dd>

                    <dt>Number of FailedThings:</dt>
                    <dd>
                        {{jobDescription.jobProcessDetails.numberOfFailedThings}}
                    </dd>

                    <dt>Number of InProgressThings:</dt>
                    <dd>
                        {{jobDescription.jobProcessDetails.numberOfInProgressThings}}
                    </dd>

                    <dt>Number of QueuedThings:</dt>
                    <dd>
                        {{jobDescription.jobProcessDetails.numberOfQueuedThings}}
                    </dd>

                    <dt>Number of RejectedThings:</dt>
                    <dd>
                        {{jobDescription.jobProcessDetails.numberOfRejectedThings}}
                    </dd>

                    <dt>Number of RemovedThings:</dt>
                    <dd>
                        {{jobDescription.jobProcessDetails.numberOfRemovedThings}}
                    </dd>

                    <dt>Number of SucceededThings:</dt>
                    <dd>
                        {{jobDescription.jobProcessDetails.numberOfSucceededThings}}
                    </dd>

                    <dt>Number of TimedOutThings:</dt>
                    <dd>
                        {{jobDescription.jobProcessDetails.numberOfTimedOutThings}}
                    </dd>

                    <dt>processingTargets:</dt>
                    <dd>
                        {{jobDescription.jobProcessDetails.processingTargets}}
                    </dd>

                </dl>
            </div>
        </div>

    </div>
    <div class="col-lg-6">
        <div class="card mb-4"
             *ngIf="jobDescription && jobDescription.document">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> Job Document
            </div>
            <div class="card-body">
                <dl class="dl-horizontal">

                    <dt>Job Type:</dt>
                    <dd>
                        <span class="text text-small">{{jobDescription.document.job_type}}</span>
                    </dd>

                    <dt>Device Type:</dt>
                    <dd>
                        <span class="text text-small">{{jobDescription.document.data.device_type}}</span>
                    </dd>

                    <dt>Version:</dt>
                    <dd>
                        {{jobDescription.document.data.version}}
                    </dd>

                    <dt>Url:</dt>
                    <dd>
                        {{jobDescription.document.data.url}}
                    </dd>

                    <ng-container *ngIf="jobDescription.document.data.crc">
                        <dt>Crc:</dt>
                        <dd>
                            {{jobDescription.document.data.crc}}
                        </dd>
                        <dt>Crc (hexadecimal):</dt>
                        <dd>
                            {{jobDescription.document.data.crc.toString(16)}}
                        </dd>
                    </ng-container>
                </dl>

            </div>
        </div>

        <div class="card mb-4"
             *ngIf="jobExecutions">
            <div class="card-header">
                <i class="fa fa-align-justify"></i>Job Executions
            </div>
            <div class="card-body">
                <div *ngFor="let jobExecution of jobExecutions">
                    <div class="row">

                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg">
                                    <p>
                                        <b>{{this.isSonic() ? 'Sonic: ' : 'Hub: '}}</b>
                                        <a class="link"
                                           (click)="navigateToDevice(jobExecution.target_id)">{{jobExecution.target_id}}</a>
                                    </p>
                                </div>
                                <div class="col-lg-auto">
                                    <button cButton
                                            *ngIf="canCancelJob(jobDescription, jobExecution)"
                                            class="btn btn-danger btn-sm me-3"
                                            (click)="cancelJob(jobId, jobExecution)">
                                        Cancel
                                    </button>
                                    <button cButton
                                            class="btn btn-secondary btn-sm"
                                            (click)="onJobExecution(jobExecution)">
                                        Details
                                    </button>
                                </div>
                                <div class="col-lg-12">
                                    <p><b>Status:</b>&nbsp;
                                        <ng-container [ngSwitch]="jobExecution.status">
                                            <c-badge *ngSwitchCase="'FAILED'"
                                                     color="danger">{{jobExecution.status}}</c-badge>
                                            <c-badge *ngSwitchCase="'IN_PROGRESS'"
                                                     color="warning">{{jobExecution.status}}</c-badge>
                                            <c-badge *ngSwitchCase="'SUCCEEDED'"
                                                     color="success">{{jobExecution.status}}</c-badge>
                                            <c-badge *ngSwitchDefault
                                                     color="secondary">{{jobExecution.status}}</c-badge>
                                        </ng-container>
                                    </p>
                                    <p><b>Started
                                        at:</b> {{jobExecution.started_at * 1000 | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}
                                    </p>
                                    <p><b>Last updated
                                        at:</b> {{jobExecution.last_updated_at * 1000 | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
