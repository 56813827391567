import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PressureReportFeedback } from '../../../../hero-labs/_internal/pressure-test';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent {

  @Input() modalTitle: string;
  @Input() modalContent: PressureReportFeedback;

  constructor(private _bsModalRef: BsModalRef) { }

  public onCancel(): void{
    this._bsModalRef.hide();

  }

}
