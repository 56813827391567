import {Injectable} from '@angular/core';
import {CrudAware, OptionalHttpParams} from '../../../common/api/CrudAware';
import {FirmwareResource} from '../resources/firmware-resource';
import {Observable} from 'rxjs';
import {HttpHeaders, HttpParams} from '@angular/common/http';
// eslint-disable-next-line max-len
import {Firmware, FirmwareIndex, CanaryData, ReleaseStage, ReleaseStageDeviceIndex, FirmwareUpgrade, FirmwareUpgradeIndex} from '../resources/dto/firmware.response';

@Injectable()
export class FirmwareService implements CrudAware<Firmware> {
    constructor(private readonly resource: FirmwareResource) {
    }

    get(id: string | number, params?: HttpParams | OptionalHttpParams): Observable<Firmware> {
        const headers = new HttpHeaders().set('Accept', 'application/vnd.wtg.firmware.v1+json');

        return this.resource.get(id, params, headers);
    }

    getMany(params?: HttpParams | OptionalHttpParams): Observable<FirmwareIndex> {
        const headers = new HttpHeaders().set('Accept', 'application/vnd.wtg.firmware-index.v1+json');
        return this.resource.getMany(params, headers);
    }

    delete(id: string | number, params?: HttpParams | OptionalHttpParams): Observable<void> {
        return this.resource.delete(id, params);
    }

    create(payload: Firmware, params?: HttpParams | OptionalHttpParams): Observable<Firmware> {
        const headers = new HttpHeaders().set('Content-Type', 'application/vnd.wtg.firmware-create.v1+json')
                        .set('Accept', 'application/vnd.wtg.firmware.v1+json');
        return this.resource.create(payload, params, headers);
    }

    update(id: string, payload: Firmware, params?: HttpParams | OptionalHttpParams): Observable<Firmware> {
        const headers = new HttpHeaders().set('Content-Type', 'application/vnd.wtg.firmware-update.v1+json')
                        .set('Accept', 'application/vnd.wtg.firmware.v1+json');
        return this.resource.update(id, payload, params, headers);
    }

    public triggerUpgrade(deviceId: string, firmwareId: string, type: string): Observable<FirmwareUpgrade> {
        return this.resource.triggerUpgrade(deviceId, firmwareId, type);
    }

    public download(firmwareId: string): Observable<Blob> {
        return this.resource.download(firmwareId);
    }

    public getReleaseStages(firmwareId: string, channelId: string): Observable<ReleaseStage[]> {
        return this.resource.getReleaseStages(firmwareId, channelId);
    }

    public getReleaseStageDevices(releaseStageId: string, params: {limit: number; offset: number}): Observable<ReleaseStageDeviceIndex> {
        return this.resource.getReleaseStageDevices(releaseStageId, params);
    }

    public approveReleaseStage(releaseStageId: string): Observable<ReleaseStage> {
        return this.resource.approveReleaseStage(releaseStageId);
    }

    public getFirmwareUpgrades(deviceId: string, params?: HttpParams | OptionalHttpParams): Observable<FirmwareUpgradeIndex> {
        return this.resource.getFirmwareUpgrades(deviceId, params);
    }

    public cancelFirmwareUpgrade(jobId: string, deviceId?: string): Observable<void> {
        return this.resource.cancelFirmwareUpgrade(jobId, deviceId);
    }

    public getCanaryData(firmwareId: string): Observable<CanaryData> {
        return this.resource.getCanaryData(firmwareId);
    }

}
