import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ThingResource} from '../../../providers/resources/thing.resource';
import {Thing} from '../../../_internal/thing';
import {PusherService} from '../../../providers/services/pusher.service';
import {BlockUIService} from 'ng-block-ui';
import {finalize, switchMap, take} from 'rxjs/operators';
import {PusherStateChangeMessage} from '../../../_internal/pusher.state.change.message';
import {environment} from '../../../../../environments/environment';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {ToastrService} from 'ngx-toastr';
import {ThingEditPageComponent} from '../thing-edit/thing-edit-page.component';
import {Hub} from '../../../_internal/hub';
import {HubResource} from '../../../providers/resources/hub.resource';
import {UserService} from '../../../providers/services/user.service';
import {AvailableFirmwareListComponent} from '../../../components/firmware-trigger/list/available-firmware-list.component';

@Component({
    selector: 'app-tile-page',
    templateUrl: './tile-page.component.html',
    styleUrls: ['./tile-page.component.scss']
})
export class TilePageComponent implements OnInit, OnDestroy {
    public thingId: string;
    public thing: Thing;
    public modalRef: BsModalRef;
    public loadingState = false;
    public pusherChannel;
    public activeTab: string;

    public statusCheck: Subscription;
    public hubId: string;
    public hub: Hub;

    constructor(private thingResource: ThingResource,
                private modalService: BsModalService,
                private blockUI: BlockUIService,
                private pusher: PusherService,
                private activatedRoute: ActivatedRoute,
                private toastrService: ToastrService,
                private hubResource: HubResource,
                public userService: UserService) {
    }

    ngOnInit() {
        this.thingId = this.activatedRoute.snapshot.paramMap.get('id');
        this.fetchDevice();
    }

    ngOnDestroy() {
        if (this.statusCheck) {
            this.statusCheck.unsubscribe();
        }
        this.closePusher();
    }

    public closePusher(): void {
        this.pusher.unsubscribe(this.thing.property_id);
    }

    public fetchDevice(pusher = true): void {
        this.blockUI.start('loader', 'Loading...');
        this.thingResource.getThing(this.thingId)
            .pipe(
                switchMap(response => {
                    this.thing = response.thing;
                    this.thingId = response.thing.id;
                    this.hubId = response.thing.hub_id;
                    this.activeTab = 'incidents';
                    return this.hubResource.get(this.hubId);
                })
            )
            .subscribe({
                next: response => {
                    this.hub = response.hub;
                    if (pusher) {
                        this.initPusher();
                    }
                },
                error: err => {
                    this.toastrService.error('An error has ocured while fetching the data. Error code: ' + err.error, 'Error.', environment.toastSettings);
                },
            });
    }

    public fetchAfterSettingsChange(pusher = true): void {
        this.blockUI.start('loader', 'Loading...');
        this.thingResource.getThing(this.thingId)
            .pipe(finalize(() => this.blockUI.stop('loader')))
            .subscribe(response => {
                this.thing = response.thing;
                if (pusher) {
                    this.initPusher();
                }
            });
    }

    public openEditModal(): void {
        this.modalRef = this.modalService.show(ThingEditPageComponent, {
            initialState: {
                thing: _.cloneDeep(this.thing)
            }
        });
        this.modalService.onHide
            .pipe(take(1))
            .subscribe(() => this.fetchAfterSettingsChange(false));
    }

    public openUpdateDialog() {
        this.modalService.show(AvailableFirmwareListComponent, {
            initialState: {listType: 'Firmwares', thing: this.thing},
            class: 'firmware-update-list',
        });
        const subscription = this.modalService.onHide.subscribe((event) => {
            if (event === 'success') {
                this.fetchDevice();
                subscription.unsubscribe();
            }
        });
    }

    public initPusher(): void {
        this.pusherChannel = this.pusher.init(this.thing.property_id);
        this.pusherChannel.bind(environment.stateChangePusherEvent, (message: PusherStateChangeMessage) => {
            if (message.message.device_id === this.thingId) {
                this.thing.state = message.message.state;
                this.loadingState = false;
            }
        });
    }

    public tabSelect($event: TabDirective): void {
        if ($event.id) {
            this.activeTab = $event.id;
        }
    }

}


