<div class="row">
    <div class="col-lg-6 col-xl-7 col-xxl-8">
        <div class="card mb-4" *ngIf="thing">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> {{ thing.name }}
                <div class="float-end buttons-row">
                    <app-favourites-button
                        location="things"
                        [rowID]="thing.id"
                        [rowName]="thing.name"
                    >
                    </app-favourites-button>
                    <span class="btn-toolbar text-center well float-end">
                        <button
                            *ngIf="this.userService.isFirmwareMaster() || this.userService.isFirmwareManager() || this.userService.isRoot()"
                            class="btn btn-secondary btn-sm"
                            (click)="openUpdateDialog()"
                        >
                            Update
                            <i class="fa fa-download"></i>
                        </button>
                        <button
                            class="btn btn-secondary btn-sm"
                            (click)="openEditModal()"
                        >
                            Edit
                            <i class="fa icons icon-pencil"></i>
                        </button>
                    </span>
                </div>
            </div>
            <div class="card-body">
                <dl class="dl-horizontal">
                    <dt>Id:</dt>
                    <dd>
                        <span class="text text-small">{{ thing.id }}</span>
                    </dd>

                    <dt>Internal name:</dt>
                    <dd>
                        {{ thing.internal_name }}
                    </dd>

                    <dt>Type:</dt>
                    <dd>
                        Tile
                    </dd>

                    <dt>Serial No:</dt>
                    <dd>
                        {{ thing.serial_no }}
                    </dd>
                    <dt>Hub ID:</dt>
                    <dd>
                        {{ thing.hub_id }}
                    </dd>

                    <dt>Firmware:</dt>
                    <dd>
                        {{ thing.version }}
                    </dd>
                    <dt>Firmware channel:</dt>
                    <dd>
                        {{thing.firmware_channel}}
                    </dd>
                    <dt>Registered at:</dt>
                    <dd>
                        <app-multi-time
                            [date]="thing.created_at"
                            [propertyId]="thing.property_id"
                            [showLocal]="false"
                            [showProperty]="false"
                        ></app-multi-time>
                    </dd>
                    <dt>Bridge connection:</dt>
                    <dd>
                        <div [ngSwitch]="thing.cloud_connection">
                            <c-badge color="success" *ngSwitchCase="true"
                                >Connected
                            </c-badge>
                            <c-badge color="danger" *ngSwitchCase="false"
                                >Disconnected
                            </c-badge>
                        </div>
                    </dd>

                    <dt>
                        Wifi rssi:
                    </dt>
                    <dd>{{ thing.wifi_rssi }}</dd>
                    <dt>Radio connection:</dt>
                    <dd>

                        <div [ngSwitch]="thing.radio_connection">
                            <c-badge color="success" *ngSwitchCase="'connected'"
                                >Connected
                            </c-badge>
                            <c-badge color="primary" *ngSwitchDefault>{{
                                thing.radio_connection
                            }}</c-badge>
                        </div>
                    </dd>
                    <dt>
                        Radio rssi:
                    </dt>
                    <dd>{{ thing.radio_rssi }}</dd>
                    <dt>Group upgrade:</dt>
                    <dd>
                        <div [ngSwitch]="thing.mass_firmware_upgrade_disabled">
                            <c-badge color="success" *ngSwitchCase="false"
                                >Allowed
                            </c-badge>
                            <c-badge color="danger" *ngSwitchCase="true"
                                >Blocked
                            </c-badge>
                        </div>
                    </dd>
                </dl>
                <app-thing-actions [thing]="thing"></app-thing-actions>
            </div>
        </div>
    </div>
    <div class="col-lg">
        <div class="card mb-4"
             *ngIf="thing">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> Telemetry
            </div>
            <div class="card-body">
                <app-tile-telemetry [telemetry]="thing.cached_telemetry"
                                     [propertyId]="thing.property_id"
                                     *ngIf="thing?.cached_telemetry"></app-tile-telemetry>
            </div>
        </div>
    </div>
</div>

<div class="mb-4" *ngIf="thing">
    <tabset>
        <tab id="incidents" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Incidents </ng-template>
            <div class="row" *ngIf="activeTab === 'incidents'">
                <div class="col-12">
                    <app-incident-list
                        [propertyId]="thing.property_id"
                    ></app-incident-list>
                </div>
            </div>
        </tab>
        <tab id="firmwares" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Firmwares </ng-template>
            <div class="row" *ngIf="activeTab === 'firmwares'">
                <div class="col-12">
                    <app-available-firmware
                        [thing]="thing"
                        [listType]="'Requested Firmwares'"
                    ></app-available-firmware>
                </div>
            </div>
        </tab>
        <tab id="logs" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Logs </ng-template>
            <div class="row" *ngIf="activeTab === 'logs'">
                <div class="col-lg-12">
                    <app-logs-list [resourceId]="thingId"></app-logs-list>
                </div>
            </div>
        </tab>
        <tab id="lifecycle" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Lifecycle events </ng-template>
            <div class="row" *ngIf="activeTab === 'lifecycle'">
                <div class="col-12">
                    <app-connectivity-timeline
                        title="Thing"
                        [hubId]="thing.hub_id"
                        [thingId]="thingId"
                        [sonicConnection]="thing.radio_connection"
                        [bridgeConnection]="thing.cloud_connection"
                    ></app-connectivity-timeline>
                </div>
            </div>
        </tab>
        <tab id="resets" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Remote resets </ng-template>
            <div class="row" *ngIf="activeTab === 'resets'">
                <div class="col-12">
                    <app-trigger-resets-list
                        [thingId]="thingId"
                    ></app-trigger-resets-list>
                </div>
            </div>
        </tab>
        <tab id="shadowlogs" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Shadow logs </ng-template>
            <div class="row" *ngIf="activeTab === 'shadowlogs'">
                <div class="col-12">
                    <app-shadow-logs [thingId]="thingId" [valveStatus]="true"></app-shadow-logs>
                </div>
            </div>
        </tab>
        <tab id="swaps" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Swaps </ng-template>
            <div class="row" *ngIf="activeTab === 'swaps'">
                <div class="col-12">
                    <app-swap-data [thingId]="thingId"></app-swap-data>
                </div>
            </div>
        </tab>
    </tabset>
</div>
